import React, { useState } from 'react';
import axios from 'axios';

const api = 'https://lkldwn27a7.execute-api.eu-west-1.amazonaws.com/001';

export default function Subscribe() {
  const [formData, setFormData] = useState({ message: '', email: '' });

  const handleSubmit = () => {
    const endpoint = api + '/contact';
    if (!formData.email) {
      return;
    }
    if (!formData.message) {
      return;
    }
    axios.post(endpoint, JSON.stringify(formData));
  };

  return (
    <section id="signup" className="signup-section">
      <div className="container">
        <div className="row">
          <div className="col-md-10 col-lg-8 mx-auto text-center">
            <i className="far fa-paper-plane fa-2x mb-2 text-white"></i>
            <h2 className="text-white mb-5">Contact Me</h2>

            <form
              onSubmit={event => {
                event.preventDefault();
                handleSubmit();
              }}
            >
              <div className="form-group">
                <input
                  name="email"
                  type="email"
                  className="form-control flex-fill mr-0 mr-sm-2 mb-3 mb-sm-0"
                  id="inputEmail"
                  placeholder="Enter your email"
                  onChange={event => {
                    const email = event.target.value;
                    setFormData(state => ({
                      ...state,
                      email,
                    }));
                  }}
                />
              </div>
              <div className="form-group">
                <textarea
                  name="message"
                  type="text"
                  className="form-control flex-fill mr-0 mr-sm-2 mb-3 mb-sm-0"
                  id="inputMessage"
                  placeholder="Enter your message"
                  onChange={event => {
                    const message = event.target.value;
                    setFormData(state => ({
                      ...state,
                      message,
                    }));
                  }}
                />
              </div>
              <div className="form-group">
                <button type="submit" className="btn btn-primary mx-auto">
                  Send
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}
