import React from 'react';

import Layout from '../components/Layout';
import Scroll from '../components/Scroll';

import config from '../../config';
import Footer from '../components/Footer';
import SocialLinks from '../components/SocialLinks';
import Subscribe from '../components/Subscribe';
import Header from '../components/Header';

import worldMapImage from '../assets/images/world-map.png';
import manufacturingImage from '../assets/images/manufacturing.jpg';
import velarImage from '../assets/images/velar.jpg';
import fPaceImage from '../assets/images/f-pace.jpg';

const IndexPage = () => (
  <Layout>
    <Header />

    <header className="masthead">
      <div className="container d-flex h-100 align-items-center">
        <div className="mx-auto text-center">
          <h1 className="mx-auto my-0 text-uppercase">
            {config.heading.split(' ').map((item, idx) => (
              <div
                key={item}
                style={{
                  textDecorationLine: idx === 0 ? 'line-through' : 'none',
                  textDecorationColor: 'white',
                }}
              >
                {item}
              </div>
            ))}
          </h1>
          <h2 className="text-white-50 mx-auto mt-2 mb-5">
            {config.subHeading}
          </h2>
          <Scroll type="id" element="about">
            <a href="#about" className="btn btn-primary">
              About
            </a>
          </Scroll>
        </div>
      </div>
    </header>

    <section id="about" className="about-section text-center">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 mx-auto">
            <h2 className="text-white mb-4">Technology &amp; Engineering</h2>
            <p className="text-white-50">
              Progressive-minded engineer with experience in software
              development, project management, data analytics and developing
              business strategy.
            </p>
          </div>
        </div>
        <img src={worldMapImage} className="img-fluid" alt="" />
      </div>
    </section>

    <section id="projects" className="projects-section bg-light">
      <div className="container">
        <div className="row align-items-center no-gutters mb-4 mb-lg-5">
          <div className="col-xl-8 col-lg-7">
            <img className="img-fluid mb-3 mb-lg-0" src={fPaceImage} alt="" />
          </div>
          <div className="col-xl-4 col-lg-5">
            <div className="featured-text text-center text-lg-left">
              <a href="https://www.jaguar.co.uk/jaguar-range/f-pace/index.html">
                <h4>Jaguar F-Pace</h4>
              </a>
              <p className="text-black-50 mb-0">
                Performance Crossover &amp; Car of the Year 2017
              </p>
            </div>
          </div>
        </div>

        <div className="row justify-content-center no-gutters mb-5 mb-lg-0">
          <div className="col-lg-6">
            <img className="img-fluid" src={manufacturingImage} alt="" />
          </div>
          <div className="col-lg-6">
            <div className="bg-black text-center h-100 project">
              <div className="d-flex h-100">
                <div className="project-text w-100 my-auto text-center text-lg-left">
                  <h4 className="text-white">Smart Connected Manufacturing</h4>
                  <p className="mb-0 text-white-50">
                    Production Facilities installation and performance analytics
                  </p>
                  <hr className="d-none d-lg-block mb-0 ml-0" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row justify-content-center no-gutters">
          <div className="col-lg-6">
            <img className="img-fluid" src={velarImage} alt="" />
          </div>
          <div className="col-lg-6 order-lg-first">
            <div className="bg-black text-center h-100 project">
              <div className="d-flex h-100">
                <div className="project-text w-100 my-auto text-center text-lg-right">
                  <a href="https://www.landrover.co.uk/vehicles/range-rover-velar/index.html">
                    <h4 className="text-white">Range Rover Velar</h4>
                  </a>
                  <p className="mb-0 text-white-50">
                    World Car Design of the Year 2018
                  </p>
                  <hr className="d-none d-lg-block mb-0 mr-0" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <Subscribe />

    <SocialLinks />
    <Footer />
  </Layout>
);

export default IndexPage;
